import React from 'react';

const Heading1 = ({ text }) => {
  return (
    <>
      <style jsx>{`
        .custom-heading {
          font-family: "SF Pro Display", sans-serif;
          font-size: 24px;
          font-weight: 400;
          line-height: 30px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #1E293B; /* Default color */
          margin: 0;
          padding: 7px;
        }
      `}</style>
      <h1 className="custom-heading">{text}</h1>
    </>
  );
};

export default Heading1;
