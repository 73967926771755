import React from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchInput = ({ placeholder, onChange }) => {
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '320px',
    height: '40px',
    padding: '8px 10px',
    gap: '10px',
    borderRadius: '4px 0px 0px 0px',
    opacity: '0.9',
    border: '1px solid #ccc',
  };

  const iconStyle = {
    fontSize: '18px',
    color: '#666',
  };

  const inputStyle = {
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    fontSize: '14px',
    padding: '0',
    color: '#333',
  };

  return (
    <div style={containerStyle}>
      <FaSearch style={iconStyle} />
      <input
        type="text"
        style={inputStyle}
        placeholder={placeholder || "Search"}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchInput;
