import React from 'react';
import PropTypes from 'prop-types';
import cancelIcon from '../../../assets/images/cancel.svg';

const CancelButton = ({ onClick, text }) => {
    return (
        <button
            onClick={onClick}
            style={{
                backgroundColor: 'white',
                color: '#000',
                fontSize: '12px',
                padding: '4px 12px',
                border: '1px solid #DFE4EA',
                borderRadius: '4px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
            }}
        >
            {true && (
                <img 
                    src={cancelIcon} 
                    alt="Cancel Icon" 
                    style={{ width: '16px', height: '16px' }} 
                />
            )}
            {text}
        </button>
    );
};

CancelButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string,
};

CancelButton.defaultProps = {
    text: 'Cancel',
};

export default CancelButton;
