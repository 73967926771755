import axios from "axios";
import authHeader from "./auth/auth-header";
import { interceptor } from "./interceptor";
import { BASE_URL } from "../Constatns";
import { saveAs } from 'file-saver'
import { toast } from "react-toastify";

const API_URL = BASE_URL;
class UserService {
  async generateOtp(email) {
    return axios.get(
      API_URL + "Otpverification/GenerateOTP?EmployeeEmail=" + email
    );
  }
  // async savePassword(body) {
  //   return axios.post(
  //     API_URL + "Otpverification/SavePassword", body
  //   );
  // }

  async verifyOtp(body) {
    return axios.post(
      API_URL + "Otpverification/VerifyOTP", body
    );
  }
  async getPermissions(employeeId) {
    interceptor();
    return axios.get(
      API_URL + "Employee/Permissions?P_EMPLOYEE_ID=" + employeeId,
      { headers: authHeader() }
    );
  }
  

  async addFcmToken(fcmBody) {
    interceptor();
    return axios.post(API_URL + "Auth/AddFcmToken", fcmBody, {
      headers: authHeader(),
  });
}

  async getEmployees(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "Employee/All", { headers: authHeader(), params });
  }
  async getEmployee(employeeId) {
    interceptor();
    return axios.get(API_URL + "Employee/GetById?P_EMPLOYEE_ID=" + employeeId, {
      headers: authHeader(),
    });
  }
  async getEmployeeSubOrdinates(employeeId) {
    interceptor();
    return axios.get(
      API_URL + "Employee/SubOrdinates?P_EMPLOYEE_ID=" + employeeId,
      { headers: authHeader() }
    );
  }
  async addEmployee(body) {
    interceptor();
    return axios.post(API_URL + "Employee/Add", body, {
      headers: authHeader(),
    });
  }
  async updateEmployee(body) {
    interceptor();
    return axios.post(API_URL + "Employee/Update", body, {
      headers: authHeader(),
    });
  }
  async getGender() {
    interceptor();
    return axios.get(API_URL + "Gender/All", { headers: authHeader() });
  }

  //Permisions Services
  async getAllPermissions(body) {
    interceptor();
    return axios.post(API_URL + "Permission/All", body, {
      headers: authHeader(),
    });
  }

  async getAllPermissionsGroup(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(
      API_URL + "Permission/Group/All", { headers: authHeader(), params}
    );
  }

  async addPermission(formValues) {
    interceptor();
    return axios.post(API_URL + "Permission/Group/Add", formValues, {
      headers: authHeader(),
    });
  }

  async updatePermission(formValues) {
    interceptor();
    return axios.post(API_URL + "Permission/Group/Update", formValues, {
      headers: authHeader(),
    });
  }

  async getOfficeLocations(body) {
    interceptor();
    return axios.post(API_URL + "OfficeLocation/All", body, {
      headers: authHeader(),
    });
  }
  async addOfficeLocation(body) {
    interceptor();
    return axios.post(API_URL + "OfficeLocation/Add", body, {
      headers: authHeader(),
    });
  }
  async updateOfficeLocation(body) {
    interceptor();
    return axios.post(API_URL + "OfficeLocation/Update", body, {
      headers: authHeader(),
    });
  }

  async getTeams(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "Team/All", { headers: authHeader(),params });
  }
  async addTeam(body) {
    interceptor();
    return axios.post(API_URL + "Team/Add", body, { headers: authHeader() });
  }
  async updateTeam(body) {
    interceptor();
    return axios.post(API_URL + "Team/Update", body, { headers: authHeader() });
  }

  async getProducts(body) {
    interceptor();
    return axios.post(API_URL + "Product/All", body, { headers: authHeader() });
  }
  async addProduct(body) {
    interceptor();
    return axios.post(API_URL + "Product/Add", body, { headers: authHeader() });
  }
  async updateProduct(body) {
    interceptor();
    return axios.post(API_URL + "Product/Update", body, {
      headers: authHeader(),
    });
  }
// Delete Product
async deleteProduct(id, fileName) {
  interceptor();
  return axios.delete(API_URL + `Product/DeleteFile?fileName=${encodeURIComponent(fileName)}&id=${id}`, { headers: authHeader() });
}
  async getDesignations(body) {
    interceptor();
    return axios.post(API_URL + "Designation/All", body, {
      headers: authHeader(),
    });
  }
  async addDesignation(body) {
    interceptor();
    return axios.post(API_URL + "Designation/Add", body, {
      headers: authHeader(),
    });
  }
  async updateDesignation(body) {
    interceptor();
    return axios.post(API_URL + "Designation/Update", body, {
      headers: authHeader(),
    });
  }

  async getDepartments(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "Department/All", { headers: authHeader(), params });
  }
  async addDepartment(body) {
    interceptor();
    return axios.post(API_URL + "Department/Add", body, {
      headers: authHeader(),
    });
  }
  async updateDepartment(body) {
    interceptor();
    return axios.post(API_URL + "Department/Update", body, {
      headers: authHeader(),
    });
  }

  async getEmploymentStatuses(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "EmploymentStatus/All", {
      headers: authHeader(), params
    });
  }

  async addEmploymentStatus(body) {
    interceptor();
    return axios.post(API_URL + "EmploymentStatus/Add", body, {
      headers: authHeader(),
    });
  }

  async updateEmploymentStatus(body) {
    interceptor();
    return axios.post(API_URL + "EmploymentStatus/Update", body, {
      headers: authHeader(),
    });
  }

  async getCallAvgEmp(body) {
    interceptor();
    return axios.post(API_URL + "CallAverage/Employee", body, {
      headers: authHeader(),
    });
  }

  async getCallAvgPro(body) {
    interceptor();
    return axios.post(API_URL + "CallAverage/Product", body, {
      headers: authHeader(),
    });
  }

  async getCallAvgPract(body) {
    interceptor();
    return axios.post(API_URL + "CallAverage/PracticeLocation", body, {
      headers: authHeader(),
    });
  }

  async getCallAvgTab(body) {
    interceptor();
    return axios.post(API_URL + "CallAverage/Table", body, {
      headers: authHeader(),
    });
  }

  async getCallAvgTasks(body) {
    interceptor();
    return axios.post(API_URL + "CallAverage/GetTasks", body, {
      headers: authHeader(),
    });
  }

  async getCities(body) {
    interceptor();
    return axios.post(API_URL + "City/All", body, {
      headers: authHeader(),
    });
  }
  async addCity(body) {
    interceptor();
    return axios.post(API_URL + "City/Add", body, { headers: authHeader() });
  }
  async updateCity(body) {
    interceptor();
    return axios.post(API_URL + "City/Update", body, { headers: authHeader() });
  }

  async getCountries() {
    interceptor();
    return axios.get(API_URL + "Country/All", { headers: authHeader() });
  }

  async getRoles(pageNumber,pageSize,searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }   
    return axios.get(API_URL + "Role/All", { headers: authHeader(),params });
  }
  async addRole(body) {
    interceptor();
    return axios.post(API_URL + "Role/Add", body, { headers: authHeader() });
  }
  async updateRole(body) {
    interceptor();
    return axios.post(API_URL + "Role/Update", body, { headers: authHeader() });
  }

  async getDoctors(body) {
    interceptor();
    return axios.post(API_URL + "Doctor/GetAllDoctorsByCityId?P_CITY_ID=" + body.cityId, body,  {
      headers: authHeader(),
    });
  }
  // async getDoctorsByPracticeLocation(body) {
  //   interceptor();
  //   console.log('Request Body:', body); // Add logging to check if practiceLocationId is passed
  //   return axios.post(API_URL + "Doctor/All?PracticeLocationId=", body.practiceLocationId, body, {
  //     headers: authHeader(),
  //   });
  // }
  async getDoctorsByPracticeLocation(body) {
    interceptor(); // Assuming you have some interceptors for request handling (e.g., for auth)
  
    try {
      // Log the request body for debugging
      console.log('Request Body:', body);
  
      // Send the POST request to the API endpoint
      const response = await axios.post(
        API_URL + "Doctor/All",  // API URL
        body,  // Send the body in the request
        {
          headers: {
            'Content-Type': 'application/json',  // Ensure it's sending as JSON
            ...authHeader(),  // Add any authorization headers if required
          }
        }
      );
  
      return response;  // Return the response
    } catch (error) {
      console.error('Error in fetching doctors:', error.response?.data || error);
      throw error;  // Throw the error to handle it properly elsewhere
    }
  }
  
  
  
  async getDoctorsWithCustomer(id) {
    interceptor();
    return axios.get(
      API_URL + "Doctor/DoctorsWithCustomerRanks/All?RankId=" + id,
      {
        headers: authHeader(),
      }
    );
  }
  async getCustomerRank(pageNumber, pageSize, searchQuery) {
    interceptor(); 
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }   
    return axios.get(API_URL + "CustomerRank/CustomerRanks/All",  { headers: authHeader(), params });
  }  
  async addCustomerRank(body) {
    interceptor();
    return axios.post(API_URL + "CustomerRank/CustomerRank/Add", body, {
      headers: authHeader(),
    });
  }
  async updateCustomerRank(body) {
    interceptor();
    return axios.post(API_URL + "CustomerRank/CustomerRank/Update", body, {
      headers: authHeader(),
    });
  }

  async generateReport(body) {
    interceptor();
    try {
      const response = await axios.post(API_URL + "PdfGenerate/GenerateReport", body, {
        headers: authHeader(),
        responseType: 'blob' 
      });
  
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CallAverageSummary.pdf'); 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("DOWNLOADED the file Successfully !", { autoClose: 2000 })
    } catch (error) {
      console.error('Failed to generate report:', error);
    }
  }

  async addDoctor(body) {
    interceptor();
    return axios.post(API_URL + "Doctor/Add", body, { headers: authHeader() });
  }
  async updateDoctor(body) {
    interceptor();
    return axios.post(API_URL + "Doctor/Update", body, {
      headers: authHeader(),
    });
  }

  async getDoctorSpecialization(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "DoctorSpecialization/Specialization/All", {
      headers: authHeader(),params
    });
  }
  async addDoctorSpecialization(body) {
    interceptor();
    return axios.post(API_URL + "DoctorSpecialization/Specialization/Add", body, {
      headers: authHeader(),
    });
  }
  async updateDoctorSpecialization(body) {
    interceptor();
    return axios.post(API_URL + "DoctorSpecialization/Specialization/Update", body, {
      headers: authHeader(),
    });
  }

  async getDoctorRank(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }   
    return axios.get(API_URL + "DoctorRank/Rank/All", { headers: authHeader(), params });
  }
  async addDoctorRank(body) {
    interceptor();
    return axios.post(API_URL + "DoctorRank/Rank/Add", body, {
      headers: authHeader(),
    });
  }
  async updateDoctorRank(body) {
    interceptor();
    return axios.post(API_URL + "DoctorRank/Rank/Update", body, {
      headers: authHeader(),
    });
  }

  async getPracticeLocations(body) {
    interceptor();
    return axios.post(API_URL + "PracticeLocation/All", body, {
      headers: authHeader(),
    });
  }
  async getPracticeLocationTypes() {
    interceptor();
    return axios.get(API_URL + "PracticeLocation/Type/All", {
      headers: authHeader(),
    });
  }

  async addPracticeLocation(body) {
    interceptor();
    return axios.post(API_URL + "PracticeLocation/Add", body, {
      headers: authHeader(),
    });
  }
  async updatePracticeLocation(body) {
    interceptor();
    return axios.post(API_URL + "PracticeLocation/Update", body, {
      headers: authHeader(),
    });
  }

  async getLocationType(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "PracticeLocation/Type/All", { headers: authHeader(), params });
  }

  async addLocationType(body) {
    interceptor();
    return axios.post(API_URL + "PracticeLocation/Type/Add", body, {
      headers: authHeader(),
    });
  }
  async updateLocationType(body) {
    interceptor();
    return axios.post(API_URL + "PracticeLocation/Type/Update", body, {
      headers: authHeader(),
    });
  }

  async getSubTasks(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "SubTask/All", { headers: authHeader(),params });
  }
  async addSubTask(body) {
    interceptor();
    return axios.post(API_URL + "SubTask/Add", body, { headers: authHeader() });
  }
  async updateSubTask(body) {
    interceptor();
    return axios.post(API_URL + "SubTask/Update", body, {
      headers: authHeader(),
    });
  }
  async getTaskPriorities(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "TaskPriority/All", { headers: authHeader(), params });
  }
  async addTaskPriority(body) {
    interceptor();
    return axios.post(API_URL + "TaskPriority/Add", body, {
      headers: authHeader(),
    });
  }
  async updateTaskPriority(body) {
    interceptor();
    return axios.post(API_URL + "TaskPriority/Update", body, {
      headers: authHeader(),
    });
  }

  async getTaskTypes(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "TaskType/All", { headers: authHeader() ,params});
  }
  async addTaskType(body) {
    interceptor();
    return axios.post(API_URL + "TaskType/Add", body, {
      headers: authHeader(),
    });
  }
  async updateTaskType(body) {
    interceptor();
    return axios.post(API_URL + "TaskType/Update", body, {
      headers: authHeader(),
    });
  }

  async getStores(body) {
    interceptor();
    return axios.post(API_URL + "Store/All", body, { headers: authHeader() });
  }
  async addStore(body) {
    interceptor();
    return axios.post(API_URL + "Store/Add", body, { headers: authHeader() });
  }
  async updateStore(body) {
    interceptor();
    return axios.post(API_URL + "Store/Update", body, {
      headers: authHeader(),
    });
  }

  async getStoreTypes(body) {
    interceptor();
    return axios.post(API_URL + "StoreType/GetAllStoreTypes", body, {
      headers: authHeader(),
    });
  }
  async addStoreType(body) {
    interceptor();
    return axios.post(API_URL + "StoreType/AddStoreType", body, {
      headers: authHeader(),
    });
  }
  async updateStoreType(body) {
    interceptor();
    return axios.post(API_URL + "StoreType/UpdateStoreType", body, {
      headers: authHeader(),
    });
  }

  async getStocks(body) {
    console.log("====================================");
    console.log(body);
    console.log("====================================");
    interceptor();
    return axios.post(API_URL + "Stock/All", body, { headers: authHeader() });
  }
  async addStock(body) {
    interceptor();
    return axios.post(API_URL + "Stock/Add", body, { headers: authHeader() });
  }
  async updateStock(body) {
    interceptor();
    return axios.post(API_URL + "Stock/Update", body, {
      headers: authHeader(),
    });
  }

  async getUnitTypes(body) {
    interceptor();
    return axios.post(API_URL + "UnitType/GetAllUnitTypes", body, {
      headers: authHeader(),
    });
  }
  async addUnitType(body) {
    interceptor();
    return axios.post(API_URL + "UnitType/AddUnitType", body, {
      headers: authHeader(),
    });
  }
  async updateUnitType(body) {
    interceptor();
    return axios.post(API_URL + "UnitType/UpdateUnitType", body, {
      headers: authHeader(),
    });
  }

  async getTaskStatuses(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "TaskStatus/All", { headers: authHeader(), params });
  }

  async getTasks(body) {
    interceptor();
    return axios.post(API_URL + "Task/GetEmployeeTask", body, {
      headers: authHeader(),
    });
  }

  // get Forms 
  async getForms() {
    interceptor();
    return axios.get(API_URL + "AWSS/GetAll", { headers: authHeader() });
  }

    // Delete Forms 
    async deleteForm(id) {
      interceptor();
      return axios.delete(API_URL + `AWSS/delete/${id}`, { headers: authHeader() });
    }

  // Upload Form
  async uploadForm(file) {
    interceptor(); 

    const formData = new FormData(); 
    formData.append('file', file); 

    // Make sure to set the headers correctly, especially the content type
    return axios.post(API_URL + "AWSS/upload", formData, {
        headers: {
            ...authHeader(), // Spread any existing headers
            'Content-Type': 'multipart/form-data' // Set the content type
        }
    }); 
}

// DownLoad Form

async downloadForm(id) {
  interceptor(); 
  
  try {
    const response = await axios.get(API_URL + `AWSS/download/${id}`, {
      headers: authHeader(),
      responseType: 'blob', 
    });
    
    return response; 
  } catch (error) {
    console.error("Error downloading form:", error);
    throw error; 
  }
}

  async taskArchive(body) {
    interceptor();
    return axios.post(API_URL + "Task/GetEmployeeTaskArchive", body, {
      headers: authHeader(),
    });
  }

  async downloadEmployee() {
    interceptor();
    return axios.get(API_URL + "Employee/Download", {
      headers: authHeader(),
    });
  }
  async getvisitProfile(pageNumber, pageSize, searchQuery) {
    interceptor();
    const params ={
      pageNumber,
      pageSize,
      searchQuery
    }
    return axios.get(API_URL + "VisitProfile/All", { headers: authHeader(),params });
  }
  async addVisitProfile(body) {
    interceptor();
    return axios.post(API_URL + "VisitProfile/Add", body, { headers: authHeader() });
  }
  
  async updateVisitProfile(body) {
    interceptor();
    return axios.put(API_URL + "VisitProfile/Update", body, {
      headers: authHeader(),
    });
  }
  async deleteVisitProfile(visitProfileId) {
    return axios.delete(API_URL + `VisitProfile/Delete/${visitProfileId}`, { headers: authHeader() });

}
async deleteVisitLocation(visitLocationId) {
  return axios.delete(API_URL + `VisitProfile/DeleteByVisitLocation/${visitLocationId}`, { headers: authHeader() });

}
}





export default new UserService();
