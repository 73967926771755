import React, { useEffect, useState, useRef } from "react";
import Excel_icon from "../../assets/images/Excel_icon.svg";
import Pdf_icon from "../../assets/images/Pdf_icon.svg";
import Word_icon from "../../assets/images/Word_icon.svg";
import Ppt_icon from "../../assets/images/Ppt_icon.svg";
import Access_icon from "../../assets/images/Access_icon.svg";
import Txt_icon from "../../assets/images/Txt_icon.svg";
import Folder_icon from "../../assets/images/Folder_icon.svg";
import HrComponent from "../reuse_components/HrComponent";
import { useDispatch, useSelector } from "react-redux";
import { deleteForm, getForms, upLoadForms } from "../../actions/userActions/formsAction";
import Loader from "../subComponents/Loader";
import AddButton from "../subComponents/buttons/AddButton";
import Search from "../subComponents/search/Search";
import { IconButton, Tooltip } from "@mui/material";
import userService from "../../services/user.service";
import "../roles/Roles.css";

const validFileExtensions = ["pdf", "xls", "doc", "ppt", "accdb", "txt"];

const Forms = () => {
  const [query, setQuery] = useState("");
  const [uploadedFileType, setUploadedFileType] = useState(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const forms = useSelector((state) => state.forms_reducer.forms);
  const uploadForm = useSelector((state) => state.forms_reducer.uploadForm);

  const filter_forms =
    forms && forms.data
      ? forms.data.filter((form) =>
          form.fileName.toLowerCase().includes(query.toLowerCase())
        )
      : [];

  useEffect(() => {
    dispatch(getForms());
  }, [dispatch]);

  useEffect(() => {
    if (uploadForm) {
      dispatch(getForms());
    }
  }, [uploadForm, dispatch]);

  const getFileExtension = (filename) => {
    const ext = filename.split(".").pop().toLowerCase();
    return ext;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const extension = getFileExtension(file.name);
      if (validFileExtensions.includes(extension)) {
        setUploadedFileType(extension);
        dispatch(upLoadForms(file));
      } else {
        alert(
          "Please choose a valid file type: PDF, XLS, DOC, PPT, Access, TXT."
        );
        setUploadedFileType(null);
      }
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleDownload = async (id) => {
    const form = forms.data.find((form) => form.id === id);
    const filename = form.fileName || `form-${id}.xlsx`;
    try {
      const response = await userService.downloadForm(id);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading form:", error);
    }
  };

  const renderFileIcon = (fileType) => {
    switch (fileType) {
      case "pdf":
        return <img src={Pdf_icon} alt="PDF" />;
      case "xls":
        return <img src={Excel_icon} alt="Excel" />;
      case "doc":
        return <img src={Word_icon} alt="Word" />;
      case "ppt":
        return <img src={Ppt_icon} alt="PPT" />;
      case "accdb":
        return <img src={Access_icon} alt="Access" />;
      case "txt":
        return <img src={Txt_icon} alt="TXT" />;
      default:
        return <img src={Folder_icon} alt="Folder" />;
    }
  };

  const handleDeleteForm = (id) => {
    dispatch(deleteForm(id)).then(() => {
      dispatch(getForms()); 
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-6 md-5">
          <h2 className="d-inline-block">Forms</h2>
        </div>
        <div
          className="col-6 md-4 d-flex justify-content-end"
          style={{ gap: "16px" }}
        >
          <Search setText={setQuery} text={query} />
          {permissions.includes("CanAddForm") && (
            <AddButton text="Upload Form" onClick={handleUploadButtonClick} />
          )}
        </div>
      </div>
      <HrComponent />

      <div className="row">
        <div className="col-md-12">
          <table className="table table-striped mt-4">
            <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
              <tr>
                <td>
                  <b className="heading_role">Name</b>
                </td>
                <td>
                  <b className="heading_role">Document Type</b>
                </td>
                <td>
                  <b className="heading_role">Actions</b>
                </td>
              </tr>
            </thead>
            <tbody className="text-left">
              {forms === "isLoading" && (
                <tr
                  style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}
                >
                  <td colSpan={3}>
                    <Loader colored={true} />
                  </td>
                </tr>
              )}
              {forms && forms !== "isLoading" && filter_forms.length === 0 && (
                <tr
                  className="text-center"
                  style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}
                >
                  <td colSpan={3}>No data found</td>
                </tr>
              )}
              {forms !== "isLoading" &&
                forms.data &&
                filter_forms
                  .sort((a, b) => a.fileName.localeCompare(b.fileName))
                  .map((row, i) => {
                    const extension = getFileExtension(row.fileName);
                    return (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td style={{ verticalAlign: "middle" }}>
                          {row.fileName}
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <div>{renderFileIcon(extension)}</div>
                        </td>
                        <td
                          style={{ verticalAlign: "middle" }}
                          className="d-flex gap-5"
                        >
                          {permissions.includes("CanAddForm") && (
                            <Tooltip
                              title="Download Form"
                              arrow
                              enterDelay={500}
                              placement="bottom"
                            >
                              <IconButton
                                onClick={() => handleDownload(row.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#002D72"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                  <path d="M7 11l5 5l5 -5" />
                                  <path d="M12 4l0 12" />
                                </svg>
                              </IconButton>
                            </Tooltip>
                          )}
                          {permissions.includes("CanDeleteForm") && (
                            <Tooltip
                              title="Delete Form"
                              arrow
                              enterDelay={500}
                              placement="bottom"
                            >
                              <IconButton
                                onClick={() => handleDeleteForm(row.id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#002D72"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6l-1 14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2L5 6"></path>
                                  <path d="M10 11v6"></path>
                                  <path d="M14 11v6"></path>
                                  <path d="M9 6V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2"></path>
                                </svg>
                              </IconButton>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
      <input
        type="file"
        accept=".pdf, .xls, .xlsx, .doc, .docx, .ppt, .pptx, .accdb, .txt"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileUpload}
      />
    </>
  );
};

export default Forms;
