import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addRoles, updateRoles } from "../../actions/userActions/roleAction";
import {
  getAllPermissionsGroup
} from "../../actions/userActions/permissionsAction";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import "./Roles.css";
import TextField from "@mui/material/TextField";
import { SaveButton } from "../subComponents/buttons/SaveButton";
import { CancelButton } from "../subComponents/buttons/CancelButton";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress } from "@mui/material";
import HrComponent from "../reuse_components/HrComponent";
function AddRoles() {
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location, "Locaton");
  
  const history = useHistory();

  const initialValues = {
    name: "",
    description: "",
    roleGroup: [],
    active: false,
  };
  const [loading, setLoading] = useState(true); // Loading state
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const pageNumber = -1;
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAllPermissionsGroup(pageNumber));
        console.log(location.role,"role location");
        
        if (location.role) {
          console.log(location.role, "Location Role");
          
          const roleGroupIds = location.role.row.permissionGroup.map(
            (item) => item.id
          );
    
          setFormValues({
            ...formValues,
            name: location.role.row.name,
            description: location.role.row.description,
            id: location.id,
            active: location.role.row.active,
            roleGroup: roleGroupIds,
          });
          setSelected(
    
            location.role.row.permissionGroup
          );
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
    
   
  }, []);
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      location.role
        ? dispatch(updateRoles(formValues))
        : dispatch(addRoles(formValues));
    }
  }, [formErrors]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if (name === "active" && formValues.active === true) {
      setFormValues({ ...formValues, [name]: false });
    } else if (name === "active" && formValues.active === false) {
      setFormValues({ ...formValues, [name]: true });
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.description) {
      errors.description = "Description is required!";
    }
    if (values.roleGroup.length <= 0) {
      errors.roleGroup = "Permission Group is required!";
    }
    return errors;
  };

  const addRolesHandle = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (location.role) {
        dispatch(updateRoles(formValues));
      } else {
        dispatch(addRoles(formValues));
      }
    }
  };
  const addRole = useSelector((state) => state.roles_reducer.addRoles);
  const allPermissionsGroup = useSelector(
    (state) => state.permissions_reducer.allPermissionsGroup
  );
  const updateRole = useSelector((state) => state.roles_reducer.updateRole);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const handleGoBack = () => {
    history.goBack();
  };

  const handlePermissionGroupChange = (event, selectedPermissionGroups) => {
    const selectedPermissionGroupIds = selectedPermissionGroups.map(permissionGroup => permissionGroup.id);
    setSelected(selectedPermissionGroups);
    setFormValues(prevValues => ({
      ...prevValues,
      roleGroup: selectedPermissionGroupIds,
    }));
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
  return (
    <>
      {addRole && addRole !== "isLoading" && (
        <Redirect to="/admin/roles" />
      )}
      {updateRole && updateRole !== "isLoading" && (
        <Redirect to="/admin/roles" />
      )}
      {!location.role && <Redirect to="/admin/AddRoles" />}
      {!permissions.includes("CanAddRole") && <Redirect to="/admin" />}
      {!permissions.includes("CanUpdateRole") && <Redirect to="/admin" />}
      <div>
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              {location.designation ? "Update Role" : "Add Role"}
            </h2>
          </div>
         <HrComponent />
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className='col-6 mt-5'>
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Name *"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            <div style={{ color: "red" }}>{formErrors.name}</div>
          </div>
          <div className='col-6 mt-5'>
            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              value={selected}
              options={allPermissionsGroup === "isLoading"
                ? [{ name: "Loading...", id: -1 }]
                :allPermissionsGroup && allPermissionsGroup.length !==0 && allPermissionsGroup.result.length === 0
                  ? [{ name: "No data", id: -1 }]
                  : allPermissionsGroup&& allPermissionsGroup.length !== 0 && allPermissionsGroup.result
                    .filter(permissionGroup => permissionGroup.active)
                    .filter(permissionGroup => !selected.some(selectedGroup => selectedGroup.id === permissionGroup.id)) 
                    .sort((a, b) => a.name.localeCompare(b.name))
              }
              getOptionLabel={(option) => option.name}
              onChange={handlePermissionGroupChange}
              name="permissionGroup"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Permissions Group *"
                />
              )}
              sx={{ width: "100%" }}
            />
            <div style={{ color: "red" }}>{formErrors.roleGroup}</div>
          </div>

          <div className='col mt-5'>
            <TextField
              style={{ width: "100%" }}
              multiline
              rows={3}
              value={formValues.description}
              name="description"
              onChange={handleChange}
              label="Description *"
            />
            <div style={{ color: "red" }}>{formErrors.description}</div>
          </div>
        </div>

        <div className="form-group mt-5" style={{ display: "flex", alignItems: "flex-start" }}>
          <input
            name="active"
            style={{ cursor: "pointer", height: "16px", width: "16px" }}
            type="checkbox"
            checked={formValues.active}
            className="ml-1 mt-1"
            onChange={handleChange}
          />

          <label
            style={{ paddingLeft: "10px", }}
            className="form-control-label"

          >
            Is this role active?
          </label>
        </div>
        <div className="col-lg-12" style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
          <CancelButton
            onClick={handleGoBack}
          />
          <SaveButton
            onClick={addRolesHandle}
            isLoading={addRole === "isLoading" || updateRole === "isLoading"}
            text={location.role ? "Update" : "Save"}
          />
        </div>
      </div>
    </>
  );
}

export default AddRoles;
