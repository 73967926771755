import React from 'react';
import PropTypes from 'prop-types'; 

const AddButton = ({ onClick, text }) => {
    return (
        <button 
            onClick={onClick} 
            style={{
                width: '135px',
                height: '35px',
                padding: '4px 12px',
                gap: 'var(--2)',
                borderRadius: '6px 0px 0px 0px',
                border: '1px solid #DFE4EA',
                backgroundColor: 'white',
                opacity: '1', 
                cursor: 'pointer', 
            }}
        >
            {text}
        </button>
    );
};

AddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired, 
};

export default AddButton;
