import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getTeams } from "../../actions/userActions/teamAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import {IconButton, Tooltip } from "@mui/material";
import Pagination from '../paginatioin/pagination';
import EditButton from "../subComponents/buttons/EditButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from "../../actions/types";


function Teams() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1)
  const [previousQuery, setPreviousQuery] = useState("");
  const [query, setQuery] = useState("");


  
  
  const teams = useSelector((state) => state.teams_reducer.teams);
  const teamsData = teams && teams.result;
  
  
  const totalPages = teams?.totalPages || 0;
  
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const handleAddTeamClick = () => {
    history.push('/admin/addTeams');
  };

  useEffect(() => {
    const pageNumber = currentPage;
  
      if (query !== previousQuery) {
        setCurrentPage(1);
      }
      dispatch(getTeams(pageNumber, SIZE_OF_PAGE, query));
      setPreviousQuery(query);
  
    }, [currentPage, query]);
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}&search=${query}`);
  };
  const handleSearchChange = (query) => {
    setQuery(query);
    setCurrentPage(1);
    history.push(`?page=1&search=${query}`);
  };
  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 md-6">
            <h2 className="d-inline-block">Teams</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <Search setText={handleSearchChange} text={query}  />
            {permissions.includes('CanAddTeam') && (
              <AddButton onClick={handleAddTeamClick} text="Add Team" />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b>Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Status</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b>Actions</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
              {teams === "isLoading" && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={3}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
               {(teams !== 'isLoading' && Array.isArray(teamsData) && teamsData.length === 0 ) || (teams && teams.length==0)&& (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={3}>No data found</td>
                    </tr>
                  )}
              
                {teams && Array.isArray(teamsData)&& teamsData !== 'isLoading' && teamsData.length !== 0 && teamsData
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((row, i) => (
                      <tr key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td style={{verticalAlign:"middle"}}>{row.name}</td>
                        <td style={{verticalAlign:"middle"}}>{row.description}</td>

                       
                        <td style={{verticalAlign:"middle"}}>
                          <span
                            style={{
                              color: row.active
                                ? "#2196F3"
                                : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                              borderRadius: "100px",
                              border: `1px solid ${row.active
                                ? "var(--primary-main, #2196F3)"
                                : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                                }`,
                              padding: "3px 10px",
                            }}
                          >
                            {row.active !== undefined ? (row.active ? "Active" : "In-active") : "Active"}
                          </span>

                        </td>
                        <td style={{verticalAlign:"middle"}}>
                          <Tooltip title="Edit Team" arrow enterDelay={500} placement="bottom">
                            <Link
                              to={{
                                pathname: "/admin/UpdateTeams",
                                team: { row },
                                id: row.id,
                              }}
                            >
                               <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                            </Link>
                          </Tooltip>
                         
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='task-pagination-wrapper'>
          <Pagination
            total={totalPages}
            current={currentPage}
            pageSize={1}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default Teams;
