import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getDepartments } from "../../actions/userActions/departmentAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import { IconButton, Tooltip } from "@mui/material";
import Pagination from "../paginatioin/pagination";
import EditButton from "../subComponents/buttons/EditButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from "../../actions/types";
import HeaderComponent from "../reuse_components/HeaderComponent";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Departments() {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const initialPageNumber = parseInt(query.get("page")) || 1;
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState(initialPageNumber);
  const [searchQuery, setSearchQuery] = useState(query.get("search") || "");
  const [previousQuery, setPreviousQuery] = useState("");
  const [permission, setPermission] = useState(false)
  const pageSize = SIZE_OF_PAGE;

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const pageNumber = currentPageNumber;

    if (searchQuery !== previousQuery) {
      setCurrentPageNumber(1);
    }

    dispatch(getDepartments(pageNumber, pageSize, searchQuery));
    setPreviousQuery(searchQuery);
    setButtonPermission()
  }, [currentPageNumber, searchQuery]);

  const departments = useSelector((state) => state.departments_reducer.departments);
  const totalPages = departments.totalPages;

  const departmentsData = departments?.result || [];

  const permissions = JSON.parse(localStorage.getItem("permissionsList"));

  const handleAddDepartmentClick = () => {
    history.push("/admin/AddDepartments");
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    history.push(`?page=${page}&search=${searchQuery}`);
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    setCurrentPageNumber(1);
    history.push(`?page=1&search=${query}`);
  };
  const setButtonPermission = () => {
    if(permissions.includes('CanAddEmployee')){
      setPermission(true);
    }
  }

  return (
    <>
      <div>
      <HeaderComponent onButtonClick={handleAddDepartmentClick} onSearchChange={handleSearchChange} HeaderTitle='Departments' visibleButton = {permission} ButtonTitle ="Add Department"/>

        {/* <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Departments</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <Search
              setText={handleSearchChange}
              text={searchQuery}
              placeholder="Search Department"
            />
            {permissions.includes("CanAddDepartment") && (
              <AddButton onClick={handleAddDepartmentClick} text="+ Add Department" />
            )}
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td><b className="heading_role">Name</b></td>
                  <td><b className="heading_role">Description</b></td>
                  <td><b className="heading_role">Status</b></td>
                  <td><b className="heading_role">Action</b></td>
                </tr>
              </thead>
              <tbody className="text-left">
               
                 {departments === 'isLoading' && (
                  <tr style={{ backgroundColor: "transparent", "--x-table-accent-bg": "transparent" }}>
                    <td colSpan={4}><Loader colored={true} /></td>
                  </tr>
                )}
                {departments !== 'isLoading' && departments.length === 0 && (
                  <tr className="text-center" style={{ backgroundColor: "transparent", "--x-table-accent-bg": "transparent" }}>
                    <td colSpan={4}>No data found</td>
                  </tr>
                )}
                {departments && departmentsData && departments !== 'isLoading' && departmentsData.length !== 0 && departments.result
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((row, i) => (
                    <tr key={i} style={{ backgroundColor: "transparent", "--x-table-accent-bg": "transparent" }}>
                      <td style={{ verticalAlign: "middle" }}>{row.name}</td>
                      <td style={{ whiteSpace: "pre-line", wordBreak: "break-word", overflowWrap: "break-word", verticalAlign: "middle" }}>
                        <div style={{
                          maxHeight: isExpanded || row.description.length <= 100 ? "none" : "3.4em",
                          overflow: "hidden",
                          position: "relative",
                          width: "65%",
                        }}>
                          {row.description}
                          {row.description.length > 100 && (
                            <span style={{ position: "absolute", right: "0px", bottom: "0", cursor: "pointer", fontSize: "20px" }} onClick={toggleExpansion}>
                              ...
                            </span>
                          )}
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <span style={{
                          color: row.active ? "#2196F3" : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                          borderRadius: "100px",
                          border: `1px solid ${row.active ? "var(--primary-main, #2196F3)" : "var(--chip-defaultEnabledBorder, #BDBDBD)"}`,
                          padding: "3px 10px",
                        }}>
                          {row.active ? "Active" : "In-active"}
                        </span>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {permissions.includes('CanUpdateDepartment') && (
                          <Tooltip title="Edit Department" arrow enterDelay={500} placement="bottom">
                            <Link to={{ pathname: "/admin/UpdateDepartments", department: { row }, id: row.id }}>
                              <IconButton><EditOutlinedIcon /></IconButton>
                            </Link>
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='task-pagination-wrapper'>
          <Pagination
            total={totalPages}
            current={currentPageNumber}
            pageSize={1}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default Departments;
