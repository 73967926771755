import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getOfficeLocations } from '../../actions/userActions/officelocationAction'
import Loader from '../subComponents/Loader'
import ServerSideSearch from '../subComponents/search/ServerSideSearch'
import Pagination from '../paginatioin/pagination'
import './OfficeLocation.css'
import AddButton from '../subComponents/buttons/AddButton'
import { IconButton, Tooltip } from "@mui/material";
import EditButton from '../subComponents/buttons/EditButton'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

function OfficeLocations() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [isExpanded, setIsExpanded] = useState(false);


  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const [selected, setSelected] = useState({
    textSearch: '',
  })
  const [filters, setFilters] = useState({
    textSearch: '',
  })
  useEffect(() => {
    dispatch(getOfficeLocations(body))
  }, [page, filters])

  const officeLocations = useSelector(
    (state) => state.officeLocations_reducer.officeLocations,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const body = {
    filter: filters,
    pageNumber: page,
    pageSize: 10,
  }

  const getPaginationNo = (pageNum) => {
    const object = []
    if (officeLocations !== 'isLoading' && officeLocations.length !== 0) {
      for (let i = 0; i < officeLocations[0].page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }

  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
  const handleAddOfficeLocationClick = () => {
    history.push('/admin/AddOfficeLocations');
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Office Locations</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <ServerSideSearch
              placeholder="Search Location"
              value={selected.textSearch}
              onChange={handleSearch}
            />
            {permissions.includes("CanAddOfficeLocation") && (
              <AddButton onClick={handleAddOfficeLocationClick} text="Add Office Location" />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    <b>Address</b>
                  </td>
                  <td>
                    <b>City</b>
                  </td>
                  <td>
                    <b>Contact No.</b>
                  </td>
                  <td>
                    <b>Head Office</b>
                  </td>
                  <td>
                    <b>Action</b>
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {officeLocations && officeLocations !== "isLoading" &&
                  officeLocations.length == 0 && (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={5}>No data found</td>
                    </tr>
                  )}
                {officeLocations == "isLoading" && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={5}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {officeLocations !== "isLoading" && officeLocations.length !== 0 &&
                  officeLocations[0].officeLocations.map((row, i) => (
                    <tr
                      key={i}
                      style={{
                        backgroundColor: "transparent",
                        "--x-table-accent-bg": "transparent",
                      }}
                    >
                      <td
                        style={{
                          whiteSpace: "pre-line",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          style={{
                            maxHeight:
                              isExpanded || row.address.length <= 100
                                ? "none"
                                : "3.4em",
                            overflow: "hidden",
                            position: "relative",
                            width: "71%",
                          }}
                        >
                          {row.address}
                          {row.address.length > 100 && (
                            <span
                              className="toggle_expand"
                              onClick={toggleExpansion}
                            >
                              ...
                            </span>
                          )}
                        </div>
                      </td>

                      <td style={{ verticalAlign: "middle" }}>
                        {row.cityName}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {row.contact1}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        {row.isHeadOffice ? "Yes" : "No"}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <Tooltip title="Edit Office Location" arrow enterDelay={500} placement="bottom">
                          <Link
                            to={{
                              pathname: "/admin/UpdateOfficeLocations",
                              officeLocation: { row },
                              id: row.id,
                            }}
                          >
                            <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                          </Link>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {officeLocations !== "isLoading" && officeLocations.length !== 0 && (
          <div className="task-pagination-wrapper">
            <Pagination
              total={
                officeLocations[0].page != undefined &&
                officeLocations[0].page.totalPages * officeLocations[0].page.pageSize
              }
              current={
                officeLocations[0].page != undefined &&
                officeLocations[0].page.pageNumber
              }
              pageSize={
                officeLocations[0].page != undefined &&
                officeLocations[0].page.pageSize
              }
              onChange={(current) => setPage(current)}
              showLessItems
            />
          </div>
        )}
      </div>
    </>
  );
}

export default OfficeLocations
