import React from 'react';
import { FaPlus } from 'react-icons/fa';

const CustomButton = ({ text, onClick, showLeftIcon = true, width = '130px' }) => {
  const buttonStyle = {
    width: width,
    height: '36px',
    padding: '8px 10px 8px 10px',
    gap: '8px',
    borderRadius: '6px',
    opacity: '0.9',
    backgroundColor: '#002D72',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    cursor: 'pointer',
  };

  const iconStyle = {
    marginRight: showLeftIcon ? '8px' : '0',
    color: '#fff',
  };

  return (
    <button style={buttonStyle} onClick={onClick}>
      {showLeftIcon && <FaPlus style={iconStyle} />}
      <span style={{ color: '#fff' }}>{text}</span>
    </button>
  );
};

export default CustomButton;
