import React from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button } from '@mui/material';

const DownloadIconButton = ({ onIconClick }) => {
    return (
        <Button
            onClick={onIconClick}
            style={{
                position: "relative",
                height: "40px",
                minWidth: "114px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "4px 14px",
                gap: "4px",
                borderRadius: "2px",
                border: "1px solid #D9D9D9",
                background: "#002D72",
                color: "#FFFFFF",
                boxShadow: "2px #0000000B",
                textTransform: "none",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#004BB4")}
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#002D72")}
        >
            <FileDownloadOutlinedIcon style={{fontSize: "20px"}} />
            Download
        </Button>
    );
};

export default DownloadIconButton;