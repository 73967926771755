import React, { useState, useEffect } from "react"; 
import { useDispatch, useSelector } from "react-redux"; 
import { getDoctorsByPracticeLocation } from "../../actions/userActions/doctorAction"; 
import { IconButton, MenuItem, Select, Checkbox, ListItemText, Chip } from "@mui/material"; 
import deleteIcon from '../../assets/images/delete.svg'; 
import locationIcon from "../../assets/images/location.svg"; 
import { deleteVisitLocation } from "../../actions/userActions/VisitProfileAction"; 
import CancelButton from "../subComponents/buttons/CustomCancelButton"; 
import PrimaryButton from "../subComponents/buttons/PrimaryButton";

function VisitlocationsRow(props) {
    const [doctorsList, setDoctorsList] = useState([]); 
    const [openModal, setOpenModal] = useState(false); 
    const [rowIndexToDelete, setRowIndexToDelete] = useState(null); 
    const dispatch = useDispatch(); 
    const practiceLocations = useSelector((state) => state.practiceLocations_reducer.practiceLocations); 
    const fetchedDoctors = useSelector((state) => state.doctors_reducer.getDoctors);

    const handleRowChange = (rowIndex, field, value) => { 
        props.setFormValues((prevState) => ({ 
            ...prevState, 
            rows: prevState.rows.map((row, index) => 
                index === rowIndex ? { ...row, [field]: value } : row 
            ), 
        })); 
    };

    const handleDeleteClick = (rowIndex) => {
        const row = props.formValues.rows[rowIndex];
        if (!row.practiceLocation && row.doctors.length === 0) {
            props.setFormValues((prevState) => ({
                ...prevState,
                rows: prevState.rows.filter((_, index) => index !== rowIndex),
            }));
        } else {
            setRowIndexToDelete(rowIndex);
            setOpenModal(true);
        }
    };
    
    const handleConfirmDelete = () => {
        const visitProfile = props.formValues.rows[rowIndexToDelete];
        if (visitProfile.visitLocationId) {
            dispatch(deleteVisitLocation(visitProfile.visitLocationId))
                .then(() => {
                    props.setFormValues((prevState) => ({
                        ...prevState,
                        rows: prevState.rows.filter((_, index) => index !== rowIndexToDelete),
                    }));
                    setOpenModal(false);
                })
                .catch((error) => {
                    console.error("Error while deleting:", error);
                });
        } else {
            props.setFormValues((prevState) => ({
                ...prevState,
                rows: prevState.rows.filter((_, index) => index !== rowIndexToDelete),
            }));
            setOpenModal(false);
        }
    };

    const handleCancelDelete = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        if (props.formValues?.rows.length > 0 && props.formValues.rows[props.index].practiceLocation !== '') {
            const doctorBody = {
                practiceLocationId: props.formValues.rows[props.index].practiceLocation,
                pageNumber: -1,
                pageSize: 0,
                textSearch: "",
            };
            dispatch(getDoctorsByPracticeLocation(doctorBody));
        }
    }, [dispatch, props.formValues.rows]);

    useEffect(() => {
        if (fetchedDoctors && Array.isArray(fetchedDoctors)) {
            setDoctorsList(fetchedDoctors);
        }
    }, [fetchedDoctors]);

   
    const handleDeleteDoctor = (doctorId) => {
        const updatedDoctors = props.row.doctors.filter((doctor) => doctor !== doctorId);
        handleRowChange(props.index, "doctors", updatedDoctors);
    };

    return (
        <>
            <div key={`${props.row.practiceLocation}-${props.index}`} className="p-3 mb-3 maindiv">
                <div className="add-visit-profile-rounded-fields-container">
                    <div className="row align-items-center">
                        <div className="col-6">
                            <label>Practice Location</label>
                            <Select
                                value={props.row.practiceLocation}
                                onChange={(e) =>
                                    handleRowChange(props.index, "practiceLocation", e.target.value)
                                }
                                fullWidth
                                MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
                            >
                                {practiceLocations === "isLoading" && (
                                    <MenuItem disabled>Loading...</MenuItem>
                                )}
                                {practiceLocations !== "isLoading" && practiceLocations.length === 0 && props.formValues.city && (
                                    <MenuItem disabled>No data</MenuItem>
                                )}
                                {practiceLocations !== "isLoading" && practiceLocations.length !== 0 &&
                                    practiceLocations[0].practiceLocations.map((location) => (
                                        <MenuItem key={location.id} value={location.id}>
                                            {location.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>

                        <div className="col-6">
                            <label>Doctors</label>
                            <Select
                                multiple
                                value={props.row.doctors}
                                onChange={(e) => handleRowChange(props.index, "doctors", e.target.value)}
                                fullWidth
                                renderValue={(selected) => (
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {selected.map((doctorId) => {
                                            const doctor = doctorsList
                                                .find((location) => location.practiceLocationId === props.row.practiceLocation)
                                                ?.doctors.find((doctor) => doctor.id === doctorId);
                                            return (
                                                doctor && (
                                                    <Chip
                                                        key={doctorId}
                                                        label={doctor.name}
                                                        onDelete={() => handleDeleteDoctor(doctorId)}
                                                        style={{ margin: 2 }}
                                                    />
                                                )
                                            );
                                        })}
                                    </div>
                                )}
                                MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
                            >
                                {doctorsList.loadingDoctors && (
                                    <MenuItem disabled>Loading...</MenuItem>
                                )}
                                {!doctorsList.loadingDoctors && doctorsList.length === 0 && (
                                    <MenuItem disabled>No data</MenuItem>
                                )}
                                {!doctorsList.loadingDoctors && doctorsList.length > 0 &&
                                    doctorsList
                                        .find((location) => location.practiceLocationId === props.row.practiceLocation)
                                        ?.doctors.map((doctor) => (
                                            <MenuItem key={doctor.id} value={doctor.id}>
                                                <Checkbox checked={props.row.doctors.includes(doctor.id)} />
                                                <ListItemText primary={doctor.name} />
                                            </MenuItem>
                                        ))}
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="removeIcon">
                    {props.index > 0 && (
                        <IconButton onClick={() => handleDeleteClick(props.index)}>
                            <img src={deleteIcon} alt="Delete" style={{ width: '24px', height: '24px' }} />
                        </IconButton>
                    )}
                </div>
            </div>

            {openModal && (
                <div className="custom-modal">
                    <div className="modal-content">
                        <div className="modal-img">
                            <img src={locationIcon} alt="Location Icon" className="modal-icon" />
                        </div>
                        <h3>Delete Location</h3>
                        <p>
                            This practice location along with all the doctors will be deleted and plans created against these doctors will be affected.
                            Are you sure you want to delete?
                        </p>
                        <div className="modal-buttons">
                            <CancelButton onClick={handleCancelDelete} text="Cancel" />
                            <PrimaryButton onClick={handleConfirmDelete} text="Delete" color="#C81E1E" />

                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default VisitlocationsRow;
