import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getLocationType } from '../../actions/userActions/locationTypeAction';
import Loader from '../subComponents/Loader';
import Search from '../subComponents/search/Search';
import Pagination from '../paginatioin/pagination';
import AddButton from '../subComponents/buttons/AddButton';
import { IconButton, Tooltip } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SIZE_OF_PAGE } from '../../actions/types';

function LocationTypes() {
    const [isExpanded, setIsExpanded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [query, setQuery] = useState("");
    const [previousQuery, setPreviousQuery] = useState("");
    const pageSize = SIZE_OF_PAGE;

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const pageNumber = currentPage;
        if (query !== previousQuery) {
            setCurrentPage(1);
        }
        dispatch(getLocationType(pageNumber, pageSize, query));
        setPreviousQuery(query);
    }, [currentPage, query, previousQuery, dispatch]);

    const locationTypesData = useSelector(
        (state) => state.location_type_reducer.locationTypes
    );

    const locationTypes = locationTypesData?.result || [];
    const totalPages = locationTypesData?.totalPages || 0;

    const permissions = JSON.parse(localStorage.getItem('permissionsList')) || [];

    const handleAddPracticeLocationTypeClick = () => {
        history.push('/admin/AddLocationTypes');
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-6 md-5">
                        <h2 className="d-inline-block">Practice Location Types</h2>
                    </div>
                    <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
                        <Search
                            setText={setQuery}
                            text={query}
                            placeholder="Search Location Type"
                        />
                        {permissions.includes('CanAddPracticeLocation') && (
                            <AddButton onClick={handleAddPracticeLocationTypeClick} text="Add Location Type" />
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-striped  mt-4">
                            <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                                <tr>
                                    <td>
                                        <b className="heading_role">Name</b>
                                    </td>
                                    <td>
                                        <b className="heading_role">Description</b>
                                    </td>
                                    <td>
                                        <b className="heading_role">Action</b>
                                    </td>
                                </tr>
                            </thead>
                            <tbody className="text-left">
                                {locationTypesData === 'isLoading' && (
                                    <tr style={{
                                        backgroundColor: "transparent",
                                        "--x-table-accent-bg": "transparent",
                                    }}>
                                        <td colSpan={3}>
                                            <Loader colored={true} />
                                        </td>
                                    </tr>
                                )}
                                {locationTypesData !== 'isLoading' && locationTypes.length === 0 && (
                                    <tr className="text-center" style={{
                                        backgroundColor: "transparent",
                                        "--x-table-accent-bg": "transparent",
                                    }}>
                                        <td colSpan={3}>No data found</td>
                                    </tr>
                                )}
                                {locationTypesData !== 'isLoading' && locationTypes
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((row, i) => (
                                        <tr key={i} style={{
                                            backgroundColor: "transparent",
                                            "--x-table-accent-bg": "transparent",
                                        }}>
                                            <td style={{ verticalAlign: "middle" }}>{row.name}</td>
                                            <td style={{
                                                whiteSpace: "pre-line",
                                                wordBreak: "break-word",
                                                overflowWrap: "break-word",
                                                verticalAlign: "middle",
                                            }}>
                                                <div style={{
                                                    maxHeight: isExpanded || row.description.length <= 100
                                                        ? "none"
                                                        : "3.4em",
                                                    overflow: "hidden",
                                                    position: "relative",
                                                    width: "67%",
                                                }}>
                                                    {row.description}
                                                    {row.description.length > 100 && (
                                                        <span style={{ position: "absolute", right: "0px", bottom: "0", cursor: "pointer", fontSize: "20px" }} onClick={toggleExpansion}>
                                                            ...
                                                        </span>
                                                    )}
                                                </div>
                                            </td>

                                            <td style={{ verticalAlign: "middle" }}>
                                                {permissions.includes('CanUpdatePracticeLocation') && (
                                                    <Tooltip title="Edit Location Type" arrow enterDelay={500} placement="bottom">
                                                        <Link to={{
                                                            pathname: '/admin/UpdateLocationTypes',
                                                            locationType: { row },
                                                            id: row.id,
                                                        }}>
                                                            <IconButton>
                                                                <EditOutlinedIcon />
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='task-pagination-wrapper'>
                    <Pagination
                        total={totalPages}
                        current={currentPage}
                        pageSize={1}
                        onChange={handlePageChange}
                    />
                </div>
            </div>
        </>
    );
}

export default LocationTypes;
