import { toast } from "react-toastify";
import userService from "../../services/user.service";
import {
    DOWNLOAD_EMPLOYEE_FAIL,
    DOWNLOAD_EMPLOYEE_PENDING,
    DOWNLOAD_EMPLOYEE_SUCCESS,
} from "../types";

export const downloadEmployee = () => async (dispatch) => {
    dispatch({
        type: DOWNLOAD_EMPLOYEE_PENDING,
        payload: { downloadEmployee: "isLoading" },
    });

    try {
        const response = await userService.downloadEmployee({
            responseType: "blob",
        });

        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition
            ? contentDisposition.split("filename=")[1].replace(/['"]/g, "")
            : "Employee.csv";

        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();

        toast.success("File downloaded successfully!", { autoClose: 2000 });
        dispatch({
            type: DOWNLOAD_EMPLOYEE_SUCCESS,
            payload: { downloadEmployee: true },
        });
    } catch (error) {
        console.error("Error downloading file:", error);
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();

        toast.error("Failed to download the file.", { autoClose: 2000 });
        dispatch({
            type: DOWNLOAD_EMPLOYEE_FAIL,
            payload: { downloadEmployee: false },
        });
    }
};