import React from 'react';
import PropTypes from 'prop-types';

const PrimaryButton = ({ onClick, text, icon, width, height, color }) => {
    return (
        <button
            onClick={onClick}
            style={{
                width: width || '74px',
                height: height || '36px',
                padding: '2px 3px 2px 3px',
                gap: '8px',
                borderRadius: '6px ',
                backgroundColor: color || '#002D72',  
                color: '#FFFFFF',
                fontSize: '14px',
                fontWeight: 'bold',
                opacity: '1',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
            }}
        >
            {icon && (
                <img 
                    src={icon} 
                    alt="Icon" 
                    style={{ width: '16px', height: '16px', marginRight: '8px' }} 
                />
            )}
            {text}
        </button>
    );
};

PrimaryButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string, 
};

export default PrimaryButton;
