import React, { useEffect } from "react";
import "./Employee.css";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEmployee } from "../../actions/userActions/employeeAction";
import { useSelector } from "react-redux";
import Loader from "../subComponents/Loader";
import HrComponent from "../reuse_components/HrComponent";

const Profile = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user?.result[0]?.user?.id;
  useEffect(() => {
    dispatch(getEmployee(userId));
  }, []);

  const employeeData = useSelector((state) => state.employees_reducer.employee);
  return (
    <>
      <div>
        <div className="row">
          <div
            className="col-md-8"
            style={{ display: "flex", alignItems: "center", gap: "10px" }}
          >
            <i onClick={history.goBack}>
              <svg
                style={{cursor: "pointer" }}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M20 11.5H7.83L13.42 5.91L12 4.5L4 12.5L12 20.5L13.41 19.09L7.83 13.5H20V11.5Z"
                  fill="#505157"
                />
              </svg>
            </i>{" "}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h2 className="d-inline-block">Profile</h2>
              {/* <p style={{ fontSize: "14px", marginLeft: "6px" }}>
                View employee details here
              </p> */}
            </div>
          </div>
          <div className="col-md-4">

          </div>
         <HrComponent />
          <div className="col-md-12 mt-5">
            <p className="personal">
              <b>Personal Details</b>
            </p>
          </div>
         <HrComponent />

          {employeeData === "isLoading" && (
            <div
              colSpan={6}
              style={{ backgroundColor: "#fff !important" }}
            >
              <Loader colored={true} />

            </div>
          )}
          {employeeData !== "isLoading" && employeeData != undefined && employeeData.length !== 0 && (
            <>
              <div className="row">
                <div className="col-md-2">
                  <div className="avatar-dp-set">
                    <p>
                      {employeeData.firstName.charAt(0)}
                      {employeeData.lastName.charAt(0)}
                    </p>

                  </div>
                  <div className="person_name"> {employeeData.firstName} {employeeData.lastName}
                  </div>
                  <div className="designation_data">
                    {employeeData.designationName}
                  </div>

                </div>
                <div className="col-md-2">
                  <div className="set_text_gap">
                    <p className="details_text">Fist Name</p>
                    <p className="details_text">Last Name</p>
                    <p className="details_text">Gender</p>
                    {/* <p className="details_text">Date of Birth</p> */}
                    <p className="details_text">Personal Email</p>
                    <p className="details_text">Address</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="set_text_gap">
                    <p className="label_data">{employeeData.firstName}</p>
                    <p className="label_data">{employeeData.lastName}</p>
                    <p className="label_data">{employeeData.genderName}</p>
                    {/* <p className="label_data">{employeeData.dateOfBirth}</p> */}
                    <p className="label_data">{employeeData.personalEmail}</p>
                    <p className="label_data">{employeeData.address}</p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="set_text_gap">
                    {/* <p className="details_text">NIC</p> */}
                    <p className="details_text">Personal Contact 1</p>
                    <p className="details_text">Personal Contact 2</p>
                    <p className="details_text">Passport No</p>
                    <p className="details_text">City</p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="set_text_gap">
                    {/* <p className="label_data">{employeeData.nic}</p> */}
                    <p className="label_data">{employeeData.personalContact1}</p>
                    <p className="label_data">{employeeData.personalContact2 || "Not available"}</p>
                    <p className="label_data">{employeeData.passport || "Not available"}</p>
                    <p className="label_data">{employeeData.cityName}</p>
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
              <div className="row mt-5">
                <div className="col-md-12 mt-5">
                  <p className="personal">
                    <b>Official Details</b>
                  </p>
                </div>
              </div>
             <HrComponent />
              <div className="row">
                <div className="col-md-2">
                  <div className="set_text_gap">
                    <p className="details_text">Department</p>
                    <p className="details_text">Designation</p>
                    <p className="details_text">Role</p>
                    <p className="details_text">Team</p>
                    <p className="details_text">Reports To</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="set_text_gap">
                    <p className="label_data">{employeeData.departmentName}</p>
                    <p className="label_data">{employeeData.designationName}</p>
                    {employeeData.employeeRoles && employeeData.employeeRoles.map((role, index) => (
                      <p className="label_data" key={index}>{role.roleName}</p>
                    ))}
                    <p className="label_data">{employeeData.teamName}</p>
                    <p className="label_data">{employeeData.reportsToName || "Not available"}</p>
                  </div></div>
                <div className="col-md-2">
                  <div className="set_text_gap">
                    <p className="details_text">Employment Status</p>
                    <p className="details_text">Official Email</p>
                    <p className="details_text">Official Contact 1</p>
                    <p className="details_text">Official Contact 2</p>
                    {/* <p className="details_text">Password</p> */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="set_text_gap">
                    <p className="label_data">{employeeData.employmentStatusName}</p>
                    <p className="label_data">{employeeData.officialEmail}</p>
                    <p className="label_data">{employeeData.officialContact1}</p>
                    <p className="label_data">{employeeData.officialContact2 || "Not available"}</p>
                    {/* <p className="label_data">{employeeData.password || "Null"}</p> */}
                  </div>
                </div>
                {/* <div className="col-md-1"></div> */}
              </div>
              <div className="row mt-5">
                <div className="col-md-2">
                  <p className="details_text">Job Location</p>
                </div>
                <div className="col-md-10">
                  <p className="label_data">{employeeData.jobLocationName || "Not available"}</p>
                </div>
              </div>
            </>
          )}

        </div>

      </div>
    </>
  );
};

export default Profile;
