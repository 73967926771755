import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllPermissions,
  getAllPermissionsGroup,
} from "../../actions/userActions/permissionsAction";
import Loader from "../subComponents/Loader";
import { Link, useHistory, useLocation } from "react-router-dom";
import Pagination from "../paginatioin/pagination"
import "./Permissions.css";
import { IconButton, Tooltip } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { SIZE_OF_PAGE } from "../../actions/types";
import HeaderComponent from "../reuse_components/HeaderComponent";


function Permissions() {
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [previousQuery, setPreviousQuery] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [permission, setPermission] = useState(false)

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const setButtonPermission = () => {
    if(permissions.includes('CanAddPermission')){
      setPermission(true);
    }
  }

  useEffect(() => {
    const pageNumber = currentPage;

    if (query !== previousQuery) {
      setCurrentPage(1);
    }
    dispatch(getAllPermissionsGroup(pageNumber, SIZE_OF_PAGE, query));
    setPreviousQuery(query);
    setButtonPermission()
  }, [currentPage, query]);

  const allPermissionsGroup = useSelector(
    (state) => state.permissions_reducer.allPermissionsGroup
  );
  const totalPages = allPermissionsGroup?.totalPages || 0;
  const allPermissionsGroupData = allPermissionsGroup?.result || [];



  
  const handleAddPermissionClick = () => {
    history.push('/admin/AddPermissions');
  };
  
  const handleSearchChange = (query) => {
    setQuery(query);
    setCurrentPage(1);
    history.push(`?page=1&search=${query}`);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}&search=${query}`);
  };
  return (
    <>
      <div>
      <HeaderComponent onButtonClick={handleAddPermissionClick} onSearchChange={handleSearchChange} HeaderTitle='Permission Groups' visibleButton = {permission} ButtonTitle ="Add Permission Group"/>
       
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Status</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {allPermissionsGroup !== 'isLoading' && allPermissionsGroupData.length === 0 &&
                    (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={4}>No data found</td>
                    </tr>
                  )}
                {allPermissionsGroup === "isLoading" && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={4}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {allPermissionsGroupData !== 'isLoading' && allPermissionsGroupData.length !== 0 && allPermissionsGroupData.map((row, i) => (
                    
                    <tr
                      key={i}
                      style={{
                        backgroundColor: "transparent",
                        "--x-table-accent-bg": "transparent",
                      }}
                    >
                      <td style={{verticalAlign:"middle"}}>{row.name}</td>
                      <td
                        style={{
                          whiteSpace: "pre-line",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          style={{
                            maxHeight:
                              isExpanded || row.description.length <= 100
                                ? "none"
                                : "3.4em",
                            overflow: "hidden",
                            position: "relative",
                            width: "65%",
                          }}
                        >
                          {row.description}
                          {row.description.length > 100 && (
                            <span
                              style={{position:"absolute", right:"0px", bottom:"0", cursor:"pointer", fontSize:"20px"}}
                              onClick={toggleExpansion}
                            >
                              ...
                            </span>
                          )}
                        </div>
                      </td>
                      <td style={{verticalAlign:"middle"}}>
                        <span
                          style={{
                            color: row.active
                              ? "#2196F3"
                              : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                            borderRadius: "100px",
                            border: `1px solid ${row.active
                              ? "var(--primary-main, #2196F3)"
                              : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                              }`,
                            padding: "3px 10px",
                          }}
                        >
                          {row.active ? "Active" : "In-active"}
                        </span>
                      </td>

                      <td style={{verticalAlign:"middle"}} >
                        <div style={{ display: "flex", gap: "15px" }}>
                          <Tooltip title="View Detail" arrow enterDelay={500} placement="bottom">
                            <Link
                              to={{
                                pathname: "/admin/ViewPermissions",
                                state: { rowData: row },
                              }}
                            >
                             <IconButton>
                                  <RemoveRedEyeOutlinedIcon />
                                </IconButton>
                            </Link>
                          </Tooltip>

                          {permissions.includes('CanUpdatePermission') && (
                            <Tooltip title="Edit Permission Group" arrow enterDelay={500} placement="bottom">
                              <Link
                                to={{
                                  pathname: "/admin/UpdatePermissions",
                                  permission: { row },
                                  id: row.id,
                                }}
                              >
                               <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {allPermissionsGroup !== "isLoading" && (
          <div className="task-pagination-wrapper">
           
            <Pagination
              total={totalPages}
              current={currentPage} 
              pageSize={1}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Permissions;
