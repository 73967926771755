import React, { useEffect, useState } from "react"; 
import { useSelector, useDispatch } from "react-redux"; 
import { useHistory } from "react-router-dom"; 
import { deleteVisitProfile, getvisitProfile } from "../../actions/userActions/VisitProfileAction"; 
import Loader from "../subComponents/Loader"; 
import SearchInput from "../subComponents/search/SearchInput"; 
import CustomButton from "../subComponents/buttons/CustomButton"; 
import { IconButton, Tooltip } from "@mui/material"; 
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'; 
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';  
import CustomPagination from "../subComponents/pagination/CustomPagination"; 
import Heading1 from "../subComponents/heading/Heading1"; 
import Breadcrumb from "../subComponents/BreadCrumbs/Breadcrumb"; 
import './VisitProfile.css';

const VisitProfile = () => { 
    const dispatch = useDispatch(); 
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1); 
    const [searchQuery, setSearchQuery] = useState(""); 
    const itemsPerPage = 10;

    const visitProfile = useSelector((state) => state.visitProfile_reducer.visitProfile); 
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    useEffect(() => {
        dispatch(getvisitProfile(currentPage, itemsPerPage, searchQuery));
       
    }, [dispatch, currentPage, searchQuery]);
    
    const handleDeleteVisitProfile = (id) => {    
        dispatch(deleteVisitProfile(id)).then(() => {
            dispatch(getvisitProfile(currentPage, itemsPerPage, searchQuery)); 
        });
    };
    
    const handleSearchChange = (e) => { 
        setSearchQuery(e.target.value); 
        setCurrentPage(1); 
    };

    const handleAddVisitProfileClick = () => { 
        history.push("/admin/AddVisitProfile"); 
    };

    const visitProfileData = visitProfile?.result || [];
    const totalPages = visitProfile?.totalPages || 0;
    const totalItems = visitProfile?.totalCount || 0;

    useEffect(() => {
        dispatch(getvisitProfile(currentPage, itemsPerPage, searchQuery));
    }, [dispatch, currentPage, searchQuery]);

    return ( 
        <div className="visit-profile-container">
            <Breadcrumb path="Manage > Visit Profile" />
            <Heading1 className="visit-profile-heading" text="Visit Profile" />
            <div className="visit-profile-content-wrapper">
                <div className="visit-profile-search-pagination">
                    <SearchInput
                        placeholder="Search"
                        onChange={handleSearchChange}
                        value={searchQuery}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <CustomPagination
                            currentPage={currentPage}
                            totalItems={totalItems} 
                            itemsPerPage={itemsPerPage}
                            totalPages={totalPages}
                            onPageChange={setCurrentPage}
                        />
                        {permissions && permissions.includes("CanAddVisitProfile") && (
                            <CustomButton text="Add Visit Profile" width="180px" onClick={handleAddVisitProfileClick} />
                        )}
                    </div>
                </div>
                <div className="visit-profile-table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>City</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visitProfile === "isLoading" ? (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: "center" }}>
                                        <Loader colored={true} />
                                    </td>
                                </tr>
                            ) : visitProfileData.length === 0 ? (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: "center" }}>
                                        No visit profiles found
                                    </td>
                                </tr>
                            ) : (
                                visitProfileData.map((row, i) => (
                                    <tr key={i}>
                                        <td style={{ verticalAlign: "middle" }}>{row.profileName}</td>
                                        <td style={{ verticalAlign: "middle" }}>{row.cityName}</td>
                                        <td>
                                        {permissions && permissions.includes("CanDeleteVisitProfile") && (
                                                <Tooltip
                                                    title="Delete Visit Profile"
                                                    arrow
                                                    enterDelay={500}
                                                    placement="bottom"
                                                >
                                                    <IconButton
                                                        onClick={() => handleDeleteVisitProfile(row.visitProfileId)}
                                                    >
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {permissions && permissions.includes("CanUpdateVisitProfile") && (
                                                <Tooltip
                                                    title="Edit Visit Profile"
                                                    arrow
                                                    enterDelay={500}
                                                    placement="bottom"
                                                >
                                                    <IconButton
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: "/admin/UpdateVisitProfile",
                                                                visitProfile: { row },
                                                                id: row.visitProfileId,
                                                            })
                                                        }
                                                    >
                                                        <EditOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                           
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default VisitProfile;
