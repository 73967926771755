import React from 'react';

const Breadcrumb = ({ path }) => {
  return (
    <nav className="breadcrumb">
      {path}
    </nav>
  );
};

export default Breadcrumb;
