import { DOWNLOAD_EMPLOYEE_FAIL, DOWNLOAD_EMPLOYEE_PENDING, DOWNLOAD_EMPLOYEE_SUCCESS } from "../../actions/types";

const initialState = { 
    downloadEmployee: [],
    };

    const download_employee_reducer = (state = initialState, action) => {
        const { type, payload } = action;
        switch (type) {
            case DOWNLOAD_EMPLOYEE_SUCCESS:
                return {
                    ...state,
                    downloadEmployee: payload.downloadEmployee,
                };
            case DOWNLOAD_EMPLOYEE_PENDING:
                return {
                    ...state,
                    downloadEmployee: payload.downloadEmployee,
                };
            case DOWNLOAD_EMPLOYEE_FAIL:
                return {
                    ...state,
                    downloadEmployee: []
                };
            default:
                return state;
        }
    }
    export default download_employee_reducer