import React, { useEffect, useState } from 'react'
import './CustomerRank.css'
import { getCustomerRank } from '../../../actions/userActions/customerRankAction'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '../../subComponents/Loader'
import ServerSideSearch from '../../subComponents/search/ServerSideSearch'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton,Tooltip } from "@mui/material";
import { Link, useHistory } from 'react-router-dom'
import AddButton from '../../subComponents/buttons/AddButton'
import Pagination from '../../paginatioin/pagination'
import { SIZE_OF_PAGE } from '../../../actions/types'
import Search from '../../subComponents/search/Search'


const CustomerRank = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState({ textSearch: '' });
  const [filters, setFilters] = useState({ textSearch: '' });
  const [isExpanded, setIsExpanded] = useState(false);
  const [query, setQuery] = useState("");
  const [previousQuery, setPreviousQuery] = useState("");
  

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const customerRankData = useSelector(
    (state) => state.customer_rank_reducer.customerRank
  )

  const customerRank = customerRankData?.result || [];
  
  const totalPages = customerRankData?.totalPages || 0;
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))

  useEffect(() => {
  const pageNumber = currentPage;

    if (query !== previousQuery) {
      setCurrentPage(1);
    }
    dispatch(getCustomerRank(pageNumber, SIZE_OF_PAGE, query));
    setPreviousQuery(query);

  }, [currentPage, query]);

  const handleAddCustomerRankClick = () => {
    history.push('/admin/AddCustomerRank');
  };
  const handleSearchChange = (query) => {
    setQuery(query);
    setCurrentPage(1);
    history.push(`?page=1&search=${query}`);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}&search=${query}`);
  };

  return (
    <div>
         <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Customer Rank</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
       
               <Search
              setText={handleSearchChange}
              text={query}
              placeholder="Search Type"
            />

{permissions.includes('CanAddCustomerRank') && (
            <AddButton
              onClick={handleAddCustomerRankClick}
              text="Add Customer Rank"
            />
          )}
        </div>
      </div>

      <div className='row'>
        <div className="col-md-12">
          <table className="table table-striped  mt-4">
            <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
              <tr>
                <td><b className="heading_role">Name</b></td>
                <td><b className="heading_role">Description</b></td>
                <td><b className="heading_role">Action</b></td>
              </tr>
            </thead>
            <tbody className="text-left">
              {customerRankData === 'isLoading' && (
                <tr style={{
                  backgroundColor: "transparent",
                  "--x-table-accent-bg": "transparent",
                }}>
                  <td colSpan={3}><Loader colored={true} /></td>
                </tr>
              )}
            {customerRankData !== 'isLoading' && customerRank.length === 0 && (
                <tr className="text-center" style={{
                  backgroundColor: 'transparent',
                  '--x-table-accent-bg': 'transparent',
                }}>
                  <td colSpan={3}>No data found</td>
                </tr>
              )}
              { customerRank !== 'isLoading' && customerRank.length !== 0 && customerRank
                .sort((a, b) => a.rankName.localeCompare(b.rankName))
                .map((row, i) => (
                  <tr key={i} style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td style={{ verticalAlign: "middle" }}>{row.rankName}</td>
                    <td style={{
                      whiteSpace: "pre-line",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                      verticalAlign: "middle",
                    }}>
                      <div style={{
                        maxHeight: isExpanded || row.rankDescription.length <= 100
                          ? "none"
                          : "3.4em",
                        overflow: "hidden",
                        position: "relative",
                        width: "65%",
                      }}>
                        {row.rankDescription}
                        {row.rankDescription.length > 100 && (
                          <span style={{ position: "absolute", right: "0px", bottom: "0", cursor: "pointer", fontSize: "20px" }} onClick={toggleExpansion}>
                            ...
                          </span>
                        )}
                      </div>
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      {permissions.includes('CanUpdateCustomerRank') && (
                        <Tooltip title="Edit CustomerRank" arrow enterDelay={500} placement="bottom">
                          <Link to={{
                            pathname: '/admin/UpdateCustomerRank',
                            customerRank: { row },
                            id: row.customerRankId,
                          }}>
                            <IconButton>
                              <EditOutlinedIcon />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className='task-pagination-wrapper'>
        <Pagination
          total={totalPages}
          current={currentPage}
          pageSize={1}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default CustomerRank;
