import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CustomPagination = ({ currentPage, totalItems, itemsPerPage, onPageChange, totalPages }) => {
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="visit-profile-pagination ">
      <span>{`${startItem} - ${endItem} of ${totalItems}`}</span>
      <button onClick={handlePrev} disabled={currentPage === 1}>
        <ArrowBackIosNewIcon />
      </button>
      <button onClick={handleNext} disabled={currentPage === totalPages}>
        <ArrowForwardIosIcon />
      </button>
    </div>
  );
};

export default CustomPagination;
