import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import "../dashboard/Dashboard.css";
import {
  getEmployeeSubOrdinates,
} from "../../actions/userActions/employeeAction";
import { getDoctorsWithCustomerRank } from "../../actions/userActions/doctoreWithCustomerAction";
import { getCustomerRank } from "../../actions/userActions/customerRankAction";
import Loader from "../subComponents/Loader";
import { Linechart } from "../subComponents/recharts/Linechart.jsx";
import { Barchart } from "../subComponents/recharts/Barchart.jsx";
import { StackedBarchart } from "../subComponents/recharts/StackedBarchart";
import { getPermissions } from "../../actions/userActions/permissionsAction";
import { Tabular } from "../subComponents/tabular/Tabular";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Search from "../subComponents/search/Search";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { generateReport } from "../../actions/userActions/generateReportAction";
import userService from "../../services/user.service";
import No_Data from '../../assets/images/no_data.svg'

function Dashboard() {
  const dispatch = useDispatch();
  const history = useHistory()
  const auth = useSelector((state) => state.auth);
  const user = JSON.parse(localStorage.getItem("user"));
  const [showKol, setShowKol] = useState(false);
  const [selected, setSelected] = useState(1);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("Call Average");
  const [callEmployee, setCallEmployee] = useState([]);
  const [callProduct, setCallProduct] = useState([]);
  const [callPracticeLocation, setCallPracticeLocation] = useState([]);
  const [callTable, setCallTable] = useState([]);

  const employeeId = user.result[0] !== undefined ? user.result[0].user.id : false;

  useEffect(() => {
    dispatch(getDoctorsWithCustomerRank(selected))
      .then(() => {
        dispatch(getCustomerRank(-1, 10, ""));
        dispatch(getEmployeeSubOrdinates(employeeId));
        dispatch(getPermissions(employeeId));
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const doctors = useSelector(
    (state) => state.doctors_with_customer_reducer.doctorsWithCustomer
  );
  const generateReportPdf = useSelector((state) => state.generate_Report.report);


  const customerRank = useSelector(
    (state) => state.customer_rank_reducer.customerRank
  );
  const CallAverageChange = () => {
    setShowKol(false);
    setSelectedTab("Call Average");
  };
  const KolChange = () => {
    setShowKol(true);
    setSelectedTab("KOL");
  };
  const [query, setQuery] = useState("");
  const filter_doctors =
    doctors !== "isLoading"
      ? doctors.filter((doctor) => {
        return doctor.name.toLowerCase().includes(query.toLowerCase());
      })
      : [];

  if (auth.isLoggedIn === false && !user) {
    return <Redirect to="/" />;
  }
  const handleRankChange = (event) => {
    setSelected(event.target.value);
    if (showKol != false) {
      dispatch(getDoctorsWithCustomerRank(event.target.value));
    }
  };
  const handleSummaryClick = () => {

    const defaultPayload = {
      productCallAverageRequest: {
        productFilters: { product: 0, employeeIds: [0] },
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString()
      },
      employeeCallAverageRequest: {
        employeeFilters: { productIds: [0], employee: 0 },
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString()
      },
      tableCallAverageRequest: {
        tableFilters: { productsIds: [0], employeeIds: [0] },
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString()
      },
      practiceLocationRequest: {
        practiceLocationFilters: { practiceLocation: 0, employeeIds: [0] },
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString()
      }
    };
    const payload = {
      productCallAverageRequest: Object.keys(callProduct).length ? callProduct : defaultPayload.productCallAverageRequest,
      employeeCallAverageRequest: Object.keys(callEmployee).length ? callEmployee : defaultPayload.employeeCallAverageRequest,
      tableCallAverageRequest: Object.keys(callTable).length ? callTable : defaultPayload.tableCallAverageRequest,
      practiceLocationRequest: Object.keys(callPracticeLocation).length ? callPracticeLocation : defaultPayload.practiceLocationRequest
    };
    dispatch(generateReport(payload));

    const body = {
      "productCallAverageRequest": callProduct,
      "employeeCallAverageRequest": callEmployee,
      "tableCallAverageRequest": callTable,
      "practiceLocationRequest": callPracticeLocation,
    }
    dispatch(userService.generateReport(body))

  };


  const onChangeCallEmployee = (newCallEmployee) => {
    setCallEmployee(newCallEmployee)

  }
  const onChangeCallProduct = (newCallProduct) => {
    setCallProduct(newCallProduct)

  }

  const onChangePracticeLocation = (newPracticeLocation) => {
    setCallPracticeLocation(newPracticeLocation)

  }
  const onChangeCallTable = (newCallTable) => {
    setCallTable(newCallTable)

  }

  return (
    <>
      <div>
        <div className="row align-items-center">
          <div className="col-4 mb-4">
            {/* Title */}
            <h1 className="h2 ls-tight text-left">Dashboard</h1>
          </div>
          {/* Actions */}
          <div className="col-8 d-flex justify-content-end" style={{ gap: "16px" }}>
            {showKol != false && (
              <>
                <Search
                  setText={setQuery}
                  text={query}
                  placeholder="Search Doctor"
                />
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Rank</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected}
                    label="Rank"
                    onChange={handleRankChange}
                    MenuProps={{ PaperProps: { style: { maxHeight: 160 } } }}
                    style={{ maxHeight: 40, borderRadius: "2px", width: "220px" }}
                  >
                    {customerRank === "isLoading" && (
                      <MenuItem disabled>
                        Loading...
                      </MenuItem>
                    )}
                    {customerRank !== "isLoading" && customerRank.length === 0 && (
                      <MenuItem disabled>
                        No data
                      </MenuItem>
                    )}
                    {customerRank !== "isLoading" && customerRank.length !== 0 && customerRank.result
                      .map(rank => (
                        <MenuItem key={rank.customerRankId} value={rank.customerRankId}>
                          {rank.rankName}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </>
            )}
            <Button
              onClick={handleSummaryClick}
              style={{
                position: "relative",
                height: "40px",
                minWidth: "114px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: " 4px 14px 4px 14px",
                gap: "4px",
                borderRadius: "2px",
                border: "1px solid #D9D9D9",
                background: "#002D72",
                color: "#FFFFFF",
                boxShadow: "2px #0000000B",
                textTransform: "none",
                cursor: "pointer",
                transition: "background-color 0.3s ease"
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#004BB4"}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = "#002D72"}
            >
              <FileDownloadOutlinedIcon style={{ fontSize: "20px" }} />
              Download Summary
            </Button>
            {/* employees list here */}
          </div>
        </div>
        {/* Nav */}
        <div className="row">
          <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
            <Tab label="Call Average" value="Call Average" onClick={CallAverageChange} />
            <Tab label="KOL" value="KOL" onClick={KolChange} />
          </Tabs>
        </div>
      </div>
      <div>
        <div className="row">
          {showKol != true && (
            <div className="col-12 mt-5"
              style={{ width: '', height: '374px' }}
            >

              <div className="row justify-content-center mt-2">
                {showKol != true && <Linechart onChangeCallEmployee={onChangeCallEmployee} />}
              </div>

              <div className="row justify-content-center mt-5">
                {showKol != true && <Barchart onChangeCallProduct={onChangeCallProduct} />}
              </div>

              <div className="row justify-content-center mt-5">
                {showKol != true && <StackedBarchart onChangePracticeLocation={onChangePracticeLocation} />}
              </div>

              <div className="row justify-content-center mt-5" >
                {showKol != true && <Tabular onChangeCallTable={onChangeCallTable} />}
              </div>
            </div>
          )}
          {showKol == true && (
            <div className="col-12  mt-5">
              <div className="row">
                <div className="col-md-12">
                  <table className="table table-striped">
                    <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                      <tr>
                        <td>
                          {" "}
                          <b className="heading_role">Name</b>{" "}
                        </td>
                        <td>
                          {" "}
                          <b className="heading_role">Specialization</b>{" "}
                        </td>
                        <td>
                          {" "}
                          <b className="heading_role">Designation</b>{" "}
                        </td>

                        <td>
                          {" "}
                          <b className="heading_role">City</b>{" "}
                        </td>
                        <td>
                          {" "}
                          <b className="heading_role">Status</b>{" "}
                        </td>
                      </tr>
                    </thead>
                    <tbody className="text-left">
                      {doctors === "isLoading" && (
                        <tr style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}>
                          <td
                            colSpan={4}
                          >
                            <Loader colored={true} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {doctors !== "isLoading" && filter_doctors.length === 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "200px",
                        width: "100%",
                      }}
                    >
                      <img src={No_Data} alt="No data available" />
                      <p>please select customer rank to display data.</p>
                    </div>
                  )}

                  {doctors !== "isLoading" && filter_doctors.length > 0 && (
                    <table className="table table-striped">
                      <tbody className="text-left">
                        {filter_doctors
                          .slice()
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((row, i) => (
                            <tr key={i} style={{ backgroundColor: "transparent" }}>
                              <td>{row.name}</td>
                              <td>{row.specializationName}</td>
                              <td>{row.rankName}</td>
                              <td>{row.cityName}</td>
                              <td>
                                <span
                                  style={{
                                    color: row.active ? "#2196F3" : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                                    borderRadius: "100px",
                                    border: `1px solid ${row.active ? "var(--primary-main, #2196F3)" : "var(--chip-defaultEnabledBorder, #BDBDBD)"}`,
                                    padding: "3px 10px",
                                  }}
                                >
                                  {row.active ? "Active" : "In-active"}
                                </span>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}



                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
