import React from "react";
import { Redirect } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/images/Logo.svg";
import { Link } from 'react-router-dom'
// import QuickFindSearchbar from "./QuickFindSearchbar";
import IconButton from "./IconButton";
import notificationIcon from "../../assets/images/notificationIcon.svg"
import moon from "../../assets/images/moon.svg"

const Header = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <>
      {user.status ? (
        <div className="bg-12 d-flex align-items-center justify-content-between">
          <div className="pl-4">
            <a className="navbar-brand" href="#">
              <img src={logo} alt="Logo" style={{ width: "128px", height: "40px" }} />
            </a>
          </div>
          {/* <div className="d-flex justify-content-center ml-auto">
            <QuickFindSearchbar />
          </div> */}
          <div className="d-flex align-items-center ml-auto gap-4 mr-2">
            <IconButton icon={moon} />
            <IconButton icon={notificationIcon} />
          </div>
          <Link className="float-right mr-4" to={{ pathname: "/admin/Profile" }}>
            <div
              className="header-avatar-wrapper"
              style={{ display: "flex", gap: "12px", alignItems: "center" }}
            >
              <div style={{ height: "40px", width: "40px" }}>
                <p>
                  {user.result[0].user.firstName.charAt(0)}
                  {user.result[0].user.lastName.charAt(0)}
                </p>
              </div>
              <span >
                <p className="name_font">
                  {user.result[0].user.firstName +
                    " " +
                    user.result[0].user.lastName}
                </p>
                <p className=" designation_font">{user.result[0].user.designation}</p>
              </span>
            </div>
          </Link>
        </div>
      ) : (
        <>
          <Redirect to="/" />
        </>
      )}
    </>
  );
};

export default Header;
