import React, { useState } from 'react'
import Search from '../subComponents/search/Search';
import AddButton from '../subComponents/buttons/AddButton';

import DownloadIconButton from '../subComponents/buttons/DownloadIconButton';
/*
props needed to use this component
1- onButtonClick (callBack Function)
2- onSearchChange (callBack Function)
3- HeaderTitle (string)
4- VisibleButton (boolean)
5- ButtonTitle (string)
*/

const HeaderComponent = ({ onButtonClick, onSearchChange, HeaderTitle, visibleButton, ButtonTitle, iconButton, onIconButtonClick }) => {
  const [query, setQuery] = useState("");

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <h2 className="d-inline-block">{HeaderTitle}</h2>
        </div>
        <div className="col-8 md-4 d-flex justify-content-end align-items-center" style={{ gap: "16px" }}>
          <Search
            setText={(value) => {
              setQuery(value);
              onSearchChange(value);
            }}
            text={query}
          />
          {iconButton && (
            <DownloadIconButton
              onIconClick={onIconButtonClick}
            />
          )}
          {visibleButton && (
            <AddButton onClick={onButtonClick} text={ButtonTitle} />
          )}

        </div>
      </div>
    </>
  )
}

export default HeaderComponent